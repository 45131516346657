<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="player listmain type-f3d f3d pcddopen game-f3d-1dw">
        <div class="data" v-for="(item,i) in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>
            <li :class="ele.selected?'selected':''" v-for="ele in item.arr" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">
                <!-- <div :class="'greenball b'+ele.label">{{ele.label}}</div> -->
                <p :class="'ball b'+Number(i)" style="margin:0">{{ ele.label }}</p>
              </ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input v-model="ele.money" @click.stop="showOrHideCheck($event, ele)" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>

              </ol>
            </li>

          </ul>
        </div>

      </div>

      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      honglvlan:['red','green','blue'],
      groupnames:'1zdw',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "佰位", arr: [] },
        { title: "拾位", arr: [] },
        { title: "个位", arr: [] },
      ],
      labelArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    };
  },
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 佰位
        let arr1 = this.filterData(resAArr, 3619, 3628, this.labelArr);
        this.rowsData[0].arr = arr1;
        // 拾位
        let arr2 = this.filterData(resAArr, 3629, 3638, this.labelArr);
        this.rowsData[1].arr = arr2;
        // 个位
        let arr3 = this.filterData(resAArr, 3639, 3648, this.labelArr);
        this.rowsData[2].arr = arr3;

        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.pcddopen .ball:after {
   
   content: "";
 
}
.type-f3d .greenball {
  /* background-image: url("../../assets/3d.png"); */
}
</style>